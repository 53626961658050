<template>
  <a v-if="address" @click="openMap">
    <v-icon :small="short" class="pr-2">mdi-google-maps</v-icon>
    <span class="underline">{{ address }}</span>
  </a>
</template>

<script>
export default {
  name: 'PlugAddressView',
  props: {
    item: Object,
    short: Boolean
  },
  computed: {
    address () {
      if (!this.item.address || !this.item.address.plz || !this.item.address.city || !this.item.address.street) return null
      if (this.short) {
        return this.item.address.city
      } else {
        return this.item.address.street + ', ' + this.item.address.plz + ' ' + this.item.address.city
      }
    },
    mapUrl () {
      if (!this.address) return null
      const mapQuery = this.item.address.street + ' ' + this.item.address.plz + ' ' + this.item.address.city
      return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(mapQuery)
    }
  },
  methods: {
    openMap () {
      window.open(this.mapUrl, '_blank')
    }
  }
}
</script>

<style scoped>
.underline {
  text-decoration: underline;
}
</style>
