<template>
  <div>
    <v-text-field
      :value="dateFormatted"
      :label="label || 'Datum'"
      readonly
      clearable
      @click:clear="val = null"
      @click="showDateDlg = true"
    />

    <v-dialog
      v-model="showDateDlg"
      width="290"
    >
      <v-date-picker
        v-model="val"
        :active-picker.sync="activePicker"
        :min="minDate"
        :max="maxDate"
        locale="de"
        :first-day-of-week="1"
        :locale-first-day-of-year="4"
        @change="showDateDlg = false"
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DateField',
  props: {
    value: String, // yyyy-mm-dd
    label: String,
    min: String,
    minToday: Boolean,
    max: String,
    maxToday: Boolean,
    startWithYear: Boolean
  },
  data () {
    return {
      val: null,
      showDateDlg: false,
      activePicker: null
    }
  },
  computed: {
    dateFormatted () {
      return this.val ? this.$dayjs(this.val).formatDate() : ''
    },
    minDate () {
      return this.min
        ? this.min
        : this.minToday
          ? new this.$dayjs().formatDateISO()
          : null
    },
    maxDate () {
      return this.max
        ? this.max
        : this.maxToday
          ? new this.$dayjs().formatDateISO()
          : null
    },
  },
  watch: {
    val (val) {
      this.$emit('input', val)
    },
    showDateDlg (show) {
      if (show && this.startWithYear && !this.val) {
        this.$nextTick(() => {
          this.activePicker = 'YEAR'
        })
      }
    }
  },
  created () {
    this.val = this.value
  }
}
</script>
