<template>
  <v-card outlined>
    <v-row>
      <v-col>
        <v-card-title class="d-flex align-start justify-start flex-nowrap">
          <user-avatar :user="user" class="mr-3"/>
          <div>
            <div>{{ postData ? postData.title : '' }}</div>
            <span class="caption caption-label">{{ postData ? (postData.schedule || postData.ts) : '' | datetimeago }}</span>
          </div>
        </v-card-title>
        <v-card-text>
          <div v-if="postData && postData.text" v-html="postData.text" class="html-text mb-3"/>
          <plug-price-view v-if="postData && postData.price" :item="postData" class="my-3"/>
          <plug-address-view v-if="postData && postData.address" :item="postData" short class="my-3"/>
        </v-card-text>
      </v-col>
      <v-col v-if="postData && postData.img" cols="4">
        <img-cmp :img="postData.img" :w="300" eager class="ma-3"/>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ImgCmp from '@/components/base/ImgCmp'
import UserAvatar from '@/components/base/UserAvatar'
import PlugAddressView from '@/components/plugs/PlugAddressView'
import PlugPriceView from '@/components/plugs/PlugPriceView'

export default {
  name: 'PostPrint',
  components: { ImgCmp, UserAvatar, PlugAddressView, PlugPriceView },
  props: {
    postId: String
  },
  data () {
    return {
      postData: null
    }
  },
  computed: {
    user () {
      return this.postData?.userId ? {
        id: this.postData.userId,
        ...this.$root.users[this.postData.userId]
      } : null
    }
  },
  created () {
    this.$fb.db.doc('posts/' + this.postId).get()
      .then(doc => {
        this.postData = {
          id: this.postId,
          ...doc.data()
        }
      })
  }
}
</script>
