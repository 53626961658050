<template>
  <v-card outlined>
    <v-row>
      <v-col>
        <v-card-title>{{ eventData ? eventData.title : '' }}</v-card-title>
        <v-card-text>
          <div v-if="eventData && eventData.text" v-html="eventData.text" class="html-text mb-3"/>
          <div v-if="eventData" class="mb-3">{{ eventData | daterange }}</div>
          <plug-address-view :item="eventData" short class="mt-3"/>
        </v-card-text>
      </v-col>
      <v-col v-if="eventData && eventData.img" cols="4">
        <img-cmp :img="eventData.img" :w="300" eager class="ma-3"/>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ImgCmp from '@/components/base/ImgCmp'
import PlugAddressView from '@/components/plugs/PlugAddressView'

export default {
  name: 'EventPrint',
  components: { ImgCmp, PlugAddressView },
  props: {
    event: Object
  },
  data () {
    return {
      eventData: this.event
    }
  },
}
</script>
