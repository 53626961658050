<template>
  <v-avatar color="grey" :size="size" @click.stop="onClick">
    <ix-img
      v-if="imgixSrc"
      :src="imgixSrc"
      :imgixParams="imgixParams"
      :width="size"
      :height="size"
      fixed
    />
    <v-img
      v-else-if="previewSrc"
      :src="previewSrc"
    />
    <span
      v-else
      class="white--text text-h5"
    >
      {{ initials }}
    </span>
  </v-avatar>
</template>

<script>
import FileMixin from '@/mixins/FileMixin.js'
const IMGIX_DEFAULT_PARAMS = { fit: 'facearea', faceindex: 1, mask: 'ellipse' }

export default {
  name: 'UserAvatar',
  mixins: [FileMixin],
  props: {
    user: Object,
    large: Boolean,
    xLarge: Boolean,
    small: Boolean
  },
  data () {
    return {
      previewSrc: null
    }
  },
  computed: {
    size () {
      return this.xLarge ? 150 : this.large ? 80 : (!this.small ? 50 : 32)
    },
    facepad () {
      return this.large ? 2.2 : (!this.small ? 2 : 1.75)
    },
    imgixSrc () {
      if (!this.$cfg.imgix) return null
      return this.user?.img?.src
        ? 'contents/' + this.user.img.src
        : null
    },
    imgixParams () {
      return {
        ...IMGIX_DEFAULT_PARAMS,
        facepad: this.facepad
      }
    },
    initials () {
      if (!this.user) return '?'
      if (this.user && this.user.forename && this.user.lastname) {
        return (this.user.forename[0] + this.user.lastname[0]).toUpperCase()
      } else {
        return this.user.name ? this.user.name[0].toUpperCase() : '?'
      }
    }
  },
  methods: {
    onClick () {
      if (this.$listeners.click) {
        this.$emit('click')
      } else if (this.user?.id) {
        this.$root.displayUserId = this.$root.displayUserId === null ? this.user.id : null
      }
    }
  },
  created () {
    if (!this.$cfg.imgix && this.user?.img?.src) {
      this.getFileURL(this.user.img.src).then(src => {
        this.previewSrc = src
      })
    }
  }
}
</script>
