<template>
  <v-app>
    <v-container>
      <v-card class="no-print mb-15">
        <v-card-title>Druckassistent</v-card-title>
        <v-card-text>
          <div class="text-overline mt-6">{{ $cfg.print.postsTitle || 'Posts' }}</div>
          <v-checkbox
            v-model="printPosts"
            :label="($cfg.print.postsTitle || 'Posts') + ' drucken'"
          />
          <date-field
            v-if="printPosts"
            v-model="begin"
            label="Ab wann"
            maxToday
            @input="generated = false"
          />
          <v-select
            v-if="printPosts"
            v-model="tagIds"
            :items="tagsOpts"
            label="Welche Themen (leer = alle)"
            multiple
            @change="generated = false"
          />
          <!-- <v-select
            v-if="printPosts"
            v-model="groupIds"
            :items="$root.groupOpts"
            label="Für wen (leer = nur an Alle)"
            @change="generated = false"
          /> -->

          <!-- <div class="text-overline mt-6">{{ $cfg.print.eventsTitle || 'Events' }}</div>
          <v-checkbox
            v-model="printEvents"
            :label="($cfg.print.eventsTitle || 'Events') + ' drucken'"
          />
          <date-field
            v-if="printEvents"
            v-model="eventsBegin"
            label="Von"
            @input="generated = false"
          />
          <date-field
            v-if="printEvents"
            v-model="eventsEnd"
            label="Bis"
            :min="eventsBegin"
            @input="generated = false"
          />
          <v-select
            v-if="printEvents"
            v-model="calIds"
            :items="calOpts"
            label="Kalender"
            multiple
            chips
            @change="generated = false"
          />

          <div class="text-overline mt-6">Geburtstage</div>
          <v-checkbox
            v-model="printBirthdays"
            label="Geburtstage drucken"
          />
          <v-select
            v-if="printBirthdays"
            v-model="birthdaysMonths"
            :items="monthOpts"
            label="Monat(e)"
            hide-details
            multiple
            chips
            @change="generated = false"
          />
          <v-checkbox
            v-if="printBirthdays"
            v-model="birthdaysYear"
            :disabled="!birthdaysMonths || birthdaysMonths.length === 0"
            label="Inkl. Jahr"
            hide-details
          /> -->
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer/>
          <v-btn v-if="!generated" color="primary" :loading="generating" @click="generate">Generieren</v-btn>
          <v-btn v-else color="primary" @click="print">Drucken</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>

      <!-- PRINT -->
      <div v-if="generated">
        <v-alert
          color="info"
          dark
          icon="mdi-information"
          elevation="2"
          class="no-print text-body-2 mb-10"
        >
          Tipps für's Drucken:<br>
          - Kopf- und Fusszeilen deaktivieren<br>
          - Hintergrundbilder aktivieren
        </v-alert>

        <div class="no-print text-center">
          <div class="text-h4">Vorschau:</div>
          <hr class="my-10">
        </div>

        <div
          class="booklet page-break"
        >
          <div class="d-flex align-center justify-space-between mb-15">
            <div class="text-h4">{{ $cfg.print.title }}</div>
            <img-cmp v-if="$cfg.logo" :img="$cfg.logo" :w="300" eager/>
          </div>

          <template v-if="posts && posts.length > 0">
            <div v-if="posts.filter(p => p.print).length > 1" class="d-flex align-baseline justify-space-between mb-10">
              <div class="text-h5">{{ $cfg.print.postsTitle || 'Posts' }}</div>
              <div>{{ $dayjs(begin) | date }} - {{ $dayjs(end) | date }}</div>
            </div>

            <div v-for="post in posts" :key="post.id" class="mb-10">
              <post-print
                :postId="post.id"
                outlined
                class="no-page-break"
                :class="post.print ? '' : 'hide-print'"
              />
              <v-checkbox
                v-model="post.print"
                label="drucken"
                class="no-print"
              />
            </div>
          </template>

          <template v-if="events && events.length > 0">
            <div class="page-break"/>

            <div class="text-h5 mt-5 mb-10">{{ $cfg.print.eventsTitle || 'Events' }}</div>

            <event-print
              v-for="event in events" :key="event.id"
              :event="event"
              class="no-page-break mb-10"
            />
          </template>

          <template v-if="birthdayUsers && birthdayUsers.length > 0">
            <div class="page-break"/>

            <div class="text-h5 mt-5 mb-10">{{ $cfg.print.birthdaysTitle || 'Birthdays' }}</div>

            <v-row>
              <v-col cols="6">
                <v-list>
                  <v-list-item
                    v-for="user in birthdayUsers.slice(0, Math.floor(birthdayUsers.length / 2))"
                    :key="user.id"
                  >
                    <v-list-item-avatar>
                      <user-avatar :user="user"/>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ user.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ user.d + '.' + user.mm + (birthdaysYear ? (' ' + user.y) : '') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="6">
                <v-list>
                  <v-list-item
                    v-for="user in birthdayUsers.slice(Math.floor(birthdayUsers.length / 2))"
                    :key="user.id"
                  >
                    <v-list-item-avatar>
                      <user-avatar :user="user"/>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ user.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ user.d + '.' + user.mm + (birthdaysYear ? (' ' + user.y) : '') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </template>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import DateField from '@/components/base/DateField'
import EventPrint from '@/components/events/EventPrint'
import ImgCmp from '@/components/base/ImgCmp'
import PostPrint from '@/components/posts/PostPrint'
import UserAvatar from '@/components/base/UserAvatar'

export default {
  components: { DateField, EventPrint, ImgCmp, PostPrint, UserAvatar },
  data () {
    return {
      printPosts: true,
      printEvents: false,
      printBirthdays: false,
      begin: null,
      end: null,
      tagIds: null,
      groupIds: null,
      calIds: null,
      eventsBegin: null,
      eventsEnd: null,
      birthdaysMonths: null,
      birthdaysYear: false,
      posts: null,
      events: null,
      birthdayUsers: null,
      generated: false,
      generating: false
    }
  },
  computed: {
    tags () {
      return Object.entries(this.$root.tags).map(([id, tag]) => ({ id, ...tag }))
    },
    tagsOpts () {
      return Object.keys(this.$root.tags)
        .map(tagId => ({ text: this.$root.tags[tagId].name, value: tagId }))
    },
    calOpts () {
      return Object.entries(this.$root.cals).map(([id, cal]) => ({ text: cal.name, value: id }))
    },
    monthOpts () {
      const monthOpts = []
      for (let i = 0; i < 12; i++) {
        monthOpts.push({
          text: this.$dayjs(new Date(null, i, 1)).format('MMMM'),
          value: (i < 10 ? '0' : '') + (i + 1)
        })
      }
      return monthOpts
    },
    // TODO: ignore group perms for PRINT?
    // usersInGroup () {
    //   if (!this.users || !this.groupId) return null
    //   return this.users.filter(user => user.claims?.groupIds?.includes(this.groupId))
    // },
    // printBooklets () {
    //   if (!this.usersInGroup || !this.posts) return null
    //   return this.usersInGroup.map(user => ({
    //     userId: user.id,
    //     userName: user.forename + ' ' + user.lastname, // important for postal address, don't use any nick names
    //     userTagIds: user.tagIds || [],
    //     address: user.address,
    //     postIds: this.posts
    //       .filter(p => !p.tagIds?.length || p.tagIds.find(tagId => user.tagIds?.includes(tagId)))
    //       .filter(p => !p.groupIds?.length || p.groupIds.find(groupId => user.claims?.groupIds?.includes(groupId)))
    //       .map(p => p.id)
    //   }))
    // }
  },
  methods: {
    async generate () {
      this.generated = false
      this.generating = true

      // posts
      const posts = []
      const postsSnap = await this.$fb.db.collection('posts')
        .where('ts', '>=', this.$dayjs(this.begin).toDate())
        .orderBy('ts', 'desc')
        .get()
      postsSnap.forEach(doc => {
        const post = { id: doc.id, ...doc.data(), print: true }
        if (post.blockId && !post.collection && !post._deleted) {
          if (!this.tagIds?.length || !post.tagIds?.length || post.tagIds.find(tagId => this.tagIds?.includes(tagId))) {
            posts.push(post)
          }
        }
      })
      this.posts = posts

      // events
      if (this.calIds && this.calIds.length > 0) {
        const beginDate = this.$dayjs(this.eventsBegin).toDate()
        const endDate = this.$dayjs(this.eventsEnd + 'T23:59:59').toDate()
        const ykw = this.$dayjs(this.eventsBegin).format('YYYY:ww')

        let beginEvents = []
        let endEvents = []
        let spanEvents = []

        // get all events with begin in range
        const beginSnap = await this.$fb.db.collection('events')
          .where('begin', '>=', beginDate)
          .where('begin', '<=', endDate)
          .get()
        beginSnap.forEach(doc => {
          beginEvents.push({
            id: doc.id,
            ...doc.data()
          })
        })

        // get all events with end in range
        const endSnap = await this.$fb.db.collection('events')
          .where('end', '>=', beginDate)
          .where('end', '<=', endDate)
          .get()
        endSnap.forEach(doc => {
          endEvents.push({
            id: doc.id,
            ...doc.data()
          })
        })

        // get all events fully overlapping (spanning) range (if more than a week)
        const spanSnap = await this.$fb.db.collection('events')
          .where('kws', 'array-contains', ykw)
          .get()
        spanSnap.forEach(doc => {
          spanEvents.push({
            id: doc.id,
            ...doc.data()
          })
        })

        const events = [...new Map([...beginEvents, ...endEvents, ...spanEvents]
          .map(e => [e.id, e])).values()] // array_unique_by_key
          .filter(e => !e._deleted)
          .sort((a, b) => a.begin < b.begin ? -1 : 1)

        this.events = events
      }

      // birthdays
      if (this.birthdaysMonths) {
        this.birthdayUsers = Object.keys(this.$root.users)
          .map(userId => ({ id: userId, ...this.$root.users[userId] }))
          .filter(u => u.birthday && this.birthdaysMonths.includes(u.birthday.substr(5, 2)) && !u._disabled) // only those with public birthday in selected date-range, and not disabled)
          .map(u => ({ ...u, y: u.birthday.substr(0, 4), m: u.birthday.substr(5, 2), mm: this.monthOpts.find(mo => mo.value === u.birthday.substr(5, 2)).text, d: u.birthday.substr(8, 2) }))
          .sort((a, b) => a.d < b.d ? -1 : 1) // sort by day
      }

      this.generating = false
      this.generated = true
    },
    print () {
      window.print()
    }
  },
  created () {
    // some defaults
    this.begin = this.$dayjs().formatDateISO().substr(0, 8) + '01' // begin of current month
    this.end = this.$dayjs().formatDateISO() // today
    this.eventsBegin = this.$dayjs().add(1, 'month').date(1).formatDateISO() // begin of next month
    const nextMonth = this.$dayjs().add(1, 'month')
    this.eventsEnd = nextMonth.date(nextMonth.daysInMonth()).formatDateISO() // end of next month

    // get all users
    this.$fb.db.collection('users').get()
      .then(snap => {
        const users = []
        snap.forEach(doc => {
          const user = { id: doc.id, ...doc.data() }
          if (!user._deleted) {
            users.push(user)
          }
        })
        users.sort((a, b) => a.name < b.name ? -1 : 1)
        this.users = users
      })
  }
}
</script>

<style scoped>
.booklet {
  margin-bottom: 3rem;
}
.hide-print {
  background: lightgrey;
  opacity: 0.5;
}
@media screen {
  .no-screen {
    display: none;
  }
}
@media print {
  .no-print,
  .hide-print {
    display: none;
  }
  .page-break {
    page-break-after: always;
  }
  .no-page-break {
    page-break-inside: avoid;
  }
}
</style>
