<template>
  <div v-if="price">
    <v-icon small class="pr-2">mdi-cash-multiple</v-icon>
    <span>{{ price }}</span>
  </div>
</template>

<script>
export default {
  name: 'PlugPriceView',
  props: {
    item: Object,
    short: Boolean
  },
  computed: {
    price () {
      const price = this.item.price
      if (price === null || price === undefined) return null
      if (price === 0) return 'gratis'
      return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(price)
    }
  }
}
</script>
